.footer {
    width: 60%;
    margin: 0 auto;
    padding: 2rem 0;
}
.footer-container{
    padding: 2rem 3rem;
    border-radius: 25px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.footer-container-light {
    background-color: var(--main-platinum);
}
.footer-container-dark {
    background-color: var(--charcoal-blue);
}
.footer-text {
    text-align: center;
}
.footer-text h1 {
    font-size: 3rem;

    font-weight: 500;
}
.footer-text p {
    font-size: 1.3rem;
    margin-bottom: 1%;

}
.footer-link {
    font-size: 1.2rem;
}
.footer-link-light {
    color: var(--dark-bg);
}
.footer-link-dark {
    color: white;
}
.footer-nav {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2rem;
    margin-top: 1rem;
}
.footer-nav-link{
    font-size: 1.5rem;
    text-decoration: none;
}
.copyright {
    display: flex;
    justify-content: space-between;
}
.copyright  {
    font-size: 1.2rem;
    cursor: pointer;
}
@media screen and (max-width: 768px) {
    .footer {
        width: 90%;
    }
    .footer-container {
        padding: 1rem 2rem;
    }
    .footer-text h1 {
        font-size: 2rem;
    }
    .footer-text p {
        font-size: 1rem;
    }
    .footer-link {
        font-size: 1rem;
    }
    .footer-nav {
        gap: 1rem;
    }
    .footer-nav-link {
        font-size: 1.2rem;
    }
}
@media screen and (max-width: 1300px) {
    .footer {
        width: 100%;
    }
}