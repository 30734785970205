body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'manrope', sans-serif;
  overflow-x: hidden;
  padding: 0;
}

@Font-face {
  font-family: 'manrope';
  src: url('./assets/fonts/manrope/Manrope-ExtraLight.ttf') format('truetype');
  font-weight: 200;
}

@font-face {
  font-family: 'manrope';
  src: url('./assets/fonts/manrope/Manrope-Light.ttf') format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'manrope';
  src: url('./assets/fonts/manrope/Manrope-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'manrope';
  src: url('./assets/fonts/manrope/Manrope-Bold.ttf') format('truetype');
  font-weight: bold;
}
@font-face {
  font-family: 'manrope';
  src: url('./assets/fonts/manrope/Manrope-ExtraBold.ttf') format('truetype');
  font-weight: 800;
}
@font-face {
  font-family: 'manrope';
  src: url('./assets/fonts/manrope/Manrope-SemiBold.ttf') format('truetype');
  font-weight: 600;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
:root {
  --light-bg: #EEEEF2;
  --main-yellow: #FFD23F;
  --dark-bg: #333;
  --chilli-red: #D64933;
  --cadet-gray: #93A8AC;
  --davy-gray: #4b4b4b;
  --charcoal-blue: #373F51;
  --main-platinum: #CFDBD5;
}