.socials-container {
    display: flex;
    justify-content: center;
    margin-top: 2rem;

    gap: 5%;
    margin: 2rem auto;
    height: 4rem;
}
.social-button-dark,
.social-button-light {
    position: relative;
    overflow: hidden;
    background-color: transparent;
    border: none;
    cursor: pointer;
    flex: 1;
    font-size: 1.5rem;
    border-radius: 25px;
    padding: 1.5rem 1rem;
}
.social-button-dark {
    background-color: var(--davy-gray);
}
.social-button-light {
    background-color: white;
}
.social-button-dark:hover .button-text,
.social-button-light:hover .button-text
{
    animation: slideOut 0.5s forwards;

}

.social-button-dark .button-text,
.social-button-dark .button-text-hover,
.social-button-light .button-text,
 .social-button-light .button-text-hover
{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.social-button-dark .button-text, 
.social-button-light .button-text
{
    opacity: 1;
}

.social-button-dark .button-text-hover,
.social-button-light .button-text-hover{
    opacity: 0;
    transition: opacity 0.5s ease;
}

.social-button-dark:hover .button-text-hover,
.social-button-light:hover .button-text-hover{
    opacity: 1;
}
.social-icon-light, .social-icon-dark {
    margin-right: 0.5rem;
}
.social-icon-light {
    color: var(--chilli-red);
}
.social-icon-dark {
    color: var(--main-yellow);
}
@keyframes slideIn {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(100%);
    }
}
@keyframes slideOut {
    0% {
        opacity: 1;
        transform: translateX(0);
    }

    100% {
        opacity: 0;
        transform: translateX(100%);
    }
}
@media screen and (max-width: 800px)
{
    .socials-container {
        gap: 2%;
    }
    
}