/* Navbar styles for both desktop and mobile */
.navbar-light, .navbar-dark {
    width: 60%;
    transition: width 0.3s;
    display: flex;
    margin: 0 auto;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;
    border-radius: 50px;
    padding: 0 1.5rem;
}
.navbar-light {
    background-color: white;
}
.navbar-dark {
    background-color: #4b4a4a;
 
}
.navbar-light a, .navbar-dark a {
    text-decoration: none;
    font-size: 1.5rem;
}
.nav-link, .nav-menu{
    text-decoration: none;
    font-size: 1.5rem;
    cursor: pointer;
}
.nav-icon {
    font-size: 1.8rem;
    margin-right: 0.8rem;
}

.nav-name-light, .nav-name-dark {
    display: flex;
    align-items: center;
}
.nav-name-light a {
    color: black;
}
.nav-name-dark a {
    color: white;
}


.subdivision-links {
    position: absolute;
    z-index: 100;
}
.flip-out-menu {
    animation: flipOut 0.5s forwards;
}

.flip-in-close {
    animation: flipIn 0.5s forwards;
}

.flip-out-close {
    animation: flipOut 0.5s forwards;
}

.flip-in-menu {
    animation: flipIn 0.5s forwards;
}

@keyframes flipOut {
    from {
        transform: rotateX(0deg);
    }

    to {
        transform: rotateX(90deg);
    }
}

@keyframes flipIn {
    from {
        transform: rotateX(90deg);
    }

    to {
        transform: rotateX(0deg);
    }
}
@keyframes menuFlipIn {
    from {
        transform: rotateX(90deg) translate(500%, 0);
    }

    to {
        transform: rotateX(0deg) translate(0, 0);
    }
}

@keyframes menuFlipOut {
    from {
        transform: rotateX(0deg) translate(500%, 0);
    }

    to {
        transform: rotateX(90deg) translate(0, 0);
    }
}

.flip-menu-in {
    animation: menuFlipIn 0.5s forwards;
}
.flip-menu-out {
    animation: menuFlipOut 0.5s forwards;
}
.close-menu-in {
    animation: menuFlipIn 0.5s forwards;
}
.close-menu-out {
    animation: menuFlipOut 0.5s forwards;
}

.nav-image {
    height: 3rem;
    width: 3rem;
    border-radius: 50%;
    margin-right: 1rem;
}

.menu-light, .menu-dark {
    position: absolute;
    margin-left: -1rem;
    z-index: 100;
    padding-bottom: 2rem;
    padding-left: 2rem;
    padding-right: 10rem;
    border-radius: 25px;
    cursor: pointer;
}


.menu-item-light, .menu-item-dark {
    margin-top: 2rem;
}
.menu-item-light a {
    color: white;
    font-size: 3rem;
} 

.menu-light {
    background-color: var(--dark-bg);
}
.menu-dark {
    background-color: var(--light-bg);
}
.menu-item-dark a {
    color: black;
    font-size: 3rem;
}

.navbar a {
    display: block;
    padding: 15px;
    text-decoration: none;
    transition: color 0.3s;
}


.menu-icon{

    
    margin-top: 5%;
    font-size: 2rem;
    display: none;
    cursor: pointer;
}
.menu-icon:hover{
    color: #0a0194;
}
.close-icon{

    margin-top: 10%;
    font-size: 3rem;
    transition: color 0.3s;
}

@media (max-width: 1300px) {

    .navbar-light, .navbar-dark {
        width: 80%;
    }

}
/* Media query for collapsing the sidebar on mobile sizes */
@media (max-width: 768px) {
        .navbar-light,
        .navbar-dark {
        width: 100%; 
        padding: 1rem 1.5rem;
        }
        .open-menu-button {
            display: flex;
            margin-top: 0.2rem;
            align-items: center;
        }
        .nav-menu{
            transform: translateY(0.5rem);
        }
        html {
            font-size: 12px;
        }
}
@media (max-width: 600px) {
    .navbar-light,
    .navbar-dark {
    width: 100%; 

    }
    .open-menu-button {
        display: flex;
        margin-top: 0.2rem;
        align-items: center;
    }

    
}