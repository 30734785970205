.light {
    background-color: #EEEEF2;
    color: #333;
    animation: themeChange 0.8s ease-in-out;
    padding-top: 2rem;
}

.dark {
    background-color: #333;
    color: #f5f5f5;
    animation: darkThemeChange 0.8s ease-in-out;
    padding-top: 2rem;
}

@keyframes themeChange {
    0% {
        background-color: #333;
        color: #f5f5f5;
    }

    100% {
        background-color: #EEEEF2;
        color: #333;
    }
}
@keyframes darkThemeChange {
    0% {
        background-color: #EEEEF2;
        color: #333;
    }

    100% {
        background-color: #333;
        color: #f5f5f5;
    }
}