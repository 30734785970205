.toggle-container {
    position: relative;
    z-index: 100;
    display: flex;
}
.toggle {
    visibility: hidden;
}
.toggle + label {
    display: flex;
    align-items: center;
    cursor: pointer;
}
.toggle + label::before {
    content :"";
    height: 2rem;
    width: 4rem;
    border-radius: 1em;
    background-color: var(--dark-bg);
    margin-right: 0.5rem;
    transition: background-color 0.8s ease;
}
.toggle+label::after {
    content: "";
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 1em;
    background-color: white;
    position: absolute;
    left: 1.5rem;
    transition: transform 0.8s ease, background-color 0.3s ease;
}
.toggle:checked + label::before {
    background-color: var(--light-bg);
}
.toggle:checked + label::after {
    transform: translateX(2rem);
    background-color: var(--dark-bg);
}

@media screen and (max-width: 800px) {  
    .toggle+label::after {
        
        left: 2rem;
    }
}
@media screen and (max-width: 600px) {
    .toggle+label::after {
        
        left: 2rem;
    }
    .toggle:checked+label::after {
        transform: translateX(1.9rem);
    }
}